import React from 'react'
import PropTypes from 'prop-types'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import 'twin.macro'
import '../styles/lazyload.css'

import Section from './section'
import { AnimateIn } from './animate-in'

const Hero = ({ image, disciplines }) =>
  image || disciplines.length ? (
    <Section tw="mt-8!">
      {image ? (
        <img
          tw="rounded w-full h-auto mb-4"
          className="lazyload"
          src={image.optimisedImages.placeholderBox}
          data-src={image.optimisedImages.src}
          data-srcset={image.optimisedImages.srcset}
          sizes="100vw"
          alt={image.altText}
        />
      ) : null}
      {disciplines.length ? (
        <AnimateIn>
          <ul tw="text-center text-sm list-none text-xs leading-loose">
            <li tw="inline-block mr-4">Our role</li>
            {disciplines.map((discipline, index) => (
              <li tw="inline-block mr-4 text-dark-grey last:mr-0" key={index}>
                {discipline.title}
              </li>
            ))}
          </ul>
        </AnimateIn>
      ) : null}
    </Section>
  ) : null

Hero.propTypes = {
  image: PropTypes.shape({
    optimisedImagesFullWidth: PropTypes.object,
    caption: PropTypes.string,
    altText: PropTypes.string,
  }),
  disciplines: PropTypes.array,
}

Hero.defaultProps = {
  image: {},
  disciplines: [],
}

export default Hero
