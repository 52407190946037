import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import kebabCase from 'lodash/kebabCase'

import 'twin.macro'

import SEO from '../../components/seo'
import SiteHead from '../../components/site-head'
import Hero from '../../components/hero'
import PageContent from '../../components/page-content'
import ArticleContainer from '../../components/article-container'
import ArticleHeading from '../../components/article-heading'
import RichArticle from '../../components/rich-article'
import ContactUsCta from '../../components/contact-us-cta'
import SiteFoot from '../../components/site-foot'

const ProjectEntry = ({ data }) => {
  const { entry } = data

  const getHelmetBodyStyleAttributeValue = style => {
    if (typeof window === 'undefined') {
      return style
    }
    return Object.keys(style)
      .map(key => `${kebabCase(key)}: ${style[key]}`)
      .join(';')
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          style: getHelmetBodyStyleAttributeValue({
            backgroundColor: entry.backgroundColour,
          }),
        }}
      />
      <SEO data={entry.seomatic} />
      <SiteHead />
      <PageContent>
        <ArticleContainer>
          <ArticleHeading heading={entry.title} year={entry.year} />
          <Hero
            image={entry.heroImage.length ? entry.heroImage[0] : null}
            disciplines={entry.disciplines}
          />
          <RichArticle blocks={entry.richArticle} />
          <ContactUsCta />
        </ArticleContainer>
        <SiteFoot />
      </PageContent>
    </>
  )
}

export default ProjectEntry

export const query = graphql`
  query($id: ID) {
    entry: craftProjectProjectEntry(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
      title
      year
      backgroundColour
      disciplines {
        title
      }
      heroImage {
        ...ImageFullWidth
      }
      richArticle {
        ...RichArticleTextBlock
        ...RichArticleQuoteBlock
        ...RichArticleFullWidthImageBlock
        ...RichArticleHalfWidthImageBlock
        ...RichArticleTextAndImageBlock
        ...RichArticleImageRowBlock
        ...RichArticleImageAndTextGridBlock
        ...RichArticleVideoBlock
        ...RichArticleAmbientVideoBlock
      }
    }
  }
`
