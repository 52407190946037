import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import 'twin.macro'

import Section from './section'
import ButtonLink from './button-link'
import { AnimateIn } from './animate-in'

const OverlayNav = () => {
  const { ctas, contactDetails } = useStaticQuery(graphql`
    {
      ctas: craftCtasGlobalSet {
        contactUsCtaBody
        contactUsCtaButtonText
      }
      contactDetails: craftContactDetailsGlobalSet {
        eMailAddress
      }
    }
  `)

  return (
    <AnimateIn>
      <Section tw="mb-8!">
        <div tw="text-center">
          <div
            tw="text-3xl xl:text-4xl font-normal leading-snug"
            dangerouslySetInnerHTML={{
              __html: ctas.contactUsCtaBody,
            }}
          ></div>
          {contactDetails.eMailAddress ? (
            <div tw="mt-8">
              <ButtonLink
                text={ctas.contactUsCtaButtonText}
                to={`mailto:${contactDetails.eMailAddress}`}
                isExternal
              />
            </div>
          ) : null}
        </div>
      </Section>
    </AnimateIn>
  )
}

export default OverlayNav
